import styles from "./index.module.scss";

const BoxWithLabelValue = ({ label, value, small, isLoading }) => {
	return (
		<section className={[styles.box, small && styles.small].join(" ")}>
			<label className={[styles.label].join(" ")}>{label}</label>
			{isLoading ? (
				<div className="d-flex justify-content-center">
					<div className="line-skeleton small" />
				</div>
			) : (
				<span className={[styles.value, "text-primary"].join(" ")}>{value}</span>
			)}
		</section>
	);
};

export default BoxWithLabelValue;
