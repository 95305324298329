import React from "react";
import Spinner from "react-bootstrap/Spinner";
const Loading = ({ size, className }) => {
	return (
		<div className={["d-inline", className].join(" ")}>
			<Spinner animation="grow" role="status" size={size}>
				<span className="visually-hidden">Loading...</span>
			</Spinner>
		</div>
	);
};

export default Loading;
