import CheckedComponent from "components/CheckedComponent";
import TableAction from "components/TableAction";
import TableStatus from "components/TableStatus";
import { PLACEHOLDER_IMAGE } from "enumerations";
import routes from "operations/routing/routes";
import { Link } from "react-router-dom";
import isoToDate from "utils/isoToDate";
export const tableData = ({ onClick, newRoute, ...props }) => [
	{
		header: "hotel",
		Component: ({ param }) => param.hotelName,
	},
	{
		header: "region",
		Component: ({ param }) => param.regionName,
	},
	{
		header: "orderCount",
		Component: ({ param }) => param.orderCount,
	},
	{
		header: "scanCount",
		Component: ({ param }) => param.scanCount,
	},
	{
		header: "date",
		Component: ({ param }) => param.date,
	},
];
