import FieldDate from "components/FieldDate";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";

const HourComponent = ({ QueryString }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const changeUrl = (params) => ({
		pathname: location.pathname,
		search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
	});

	const navigateUrl = (obj) => navigate(changeUrl(obj));

	return (
		<>
			<FieldDate
				{...{
					placeholder: "fromHour",
					label: "fromHour ",
					disables: {
						now: true,
					},
					selected: QueryString.fromHour && moment().hour(QueryString.fromHour).minute(0).second(0).toDate(),
				}}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={60}
				timeCaption="Time"
				dateFormat="HH:mm"
				showTimeInput={false}
				showTimeCaption={false}
				onChange={(e) => {
					// let date = moment(e).hour();
					// console.log({ date });
					navigateUrl({ fromHour: moment(e).hour(), page: 1 });
				}}
			/>
			<FieldDate
				{...{
					placeholder: "toHour",
					label: "toHour ",
					disables: {
						now: true,
					},
					selected: QueryString.toHour && moment().hour(QueryString.toHour).minute(0).second(0).toDate(),
				}}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={60}
				timeCaption="Time"
				dateFormat="HH:mm"
				showTimeInput={false}
				showTimeCaption={false}
				onChange={(e) => {
					navigateUrl({ toHour: moment(e).hour(), page: 1 });
				}}
			/>
		</>
	);
};

export default HourComponent;
