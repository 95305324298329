import routes from "operations/routing/routes.js";
import React, { Fragment, useState, useRef, Children, useEffect, useMemo } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
// import { Table, Pagination } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import PaginationM from "../PaginationM";
import DropDownBootstrap from "../DropDownBootstrap";
import { SORT_OPTION, SORT_OPTION_OBJECT, STATUSES, STATUSES_OBJECT, tableDisplayed } from "enumerations";

import TableSkeleton from "components/TableSkeleton";
import { TABLE_STATUS } from "./enum";
import { convertorArrayOption } from "utils/convertor";
import RemoveNullObjectValue from "utils/RemoveNullObjectValue";
import { useTranslation } from "react-i18next";
import FieldDate from "components/FieldDate";
import moment from "moment";
import Loading from "components/Loading";

const TableComponent = ({
	title,
	data,
	tableOptions,
	QueryString,
	search,
	pagination,
	count,
	loading,
	actions,
	checkbox,
	handleAction,
	status,
	dating,
	disables,
	excelButton,
	isHotel,
}) => {
	disables = disables || {};
	console.log({ data, tableOptions });
	const inputRef = useRef();
	const [t] = useTranslation();

	const { limit } = QueryString;

	const location = useLocation();
	const navigate = useNavigate();

	const changeUrl = (params) => ({
		pathname: location.pathname,
		search: new URLSearchParams(RemoveNullObjectValue({ ...QueryString, ...params })).toString(),
	});

	const navigateUrl = (obj) => navigate(changeUrl(obj));
	const [toggleSubTable, setToggleSubTable] = useState();

	//  ---------------------------------------------------------------------- checkBox
	const [checkedItems, setCheckedItems] = useState({}); // its has to come from the component

	const setStateData = checkbox?.setState || setCheckedItems;

	const stateData = checkbox?.state || checkedItems;

	const toggleCheckboxFun = (data) => {
		console.log({ data }, !!stateData[data.id]);
		if (!!stateData[data.id]) {
			const { [data.id]: removing, ...prop } = stateData;
			console.log({ removing, prop }, "props removing");
			setStateData(prop);
		} else setStateData((p) => ({ ...p, [data.id]: data }));
	};

	const toggleCheckboxFunAll = (e, data) => {
		let obj = {};
		console.log({ checked: e.target.checked });
		if (!e.target.checked) {
			for (let i = 0; i < data.length; i++) {
				obj = stateData;
				let selected = data[i];
				delete obj[selected.id];
			}
			setStateData((p) => ({ ...p, ...obj }));

			console.log({ obj });
		} else {
			for (let i = 0; i < data.length; i++) {
				let selected = data[i];
				obj[selected.id] = selected;
			}
			setStateData((p) => ({ ...p, ...obj }));
		}
	};

	const selectedAllCheckbox = data?.filter?.((x) => stateData[x.id])?.length === data?.length;
	const isSelect = Object.values(stateData || {}).filter((x) => x)?.length;
	let showing = pagination?.activePage * limit;
	if (showing > count) showing = count;
	// if( !count &&count !==0)

	// ------------------------------------------ Search

	function cancelSearchHandler() {
		let value = inputRef.current.value;
		if (value) {
			delete QueryString["search_text"];
			changeUrl({ page: 1 });
		}
	}

	function handleSearch(e) {
		e.preventDefault();
		let value = inputRef.current.value;
		if (value) {
			if (value?.trim()) {
				navigate(changeUrl({ search_text: value, page: 1 }));
			} else {
				delete QueryString["search_text"];
				navigate(changeUrl({ page: 1 }));
			}
		} else {
			delete QueryString["search_text"];
			navigate(changeUrl({ page: 1 }));
		}
	}

	// ------------------------------------------------------- sorting
	const DEFAULT_STATUS = [{ label: "Tümü", value: undefined }, ...(status || TABLE_STATUS)];
	const DEFAULT_STATUS_OBJECT = {};
	convertorArrayOption(DEFAULT_STATUS, DEFAULT_STATUS_OBJECT);

	// useMemo(() => convertorArrayOption(DEFAULT_STATUS, DEFAULT_STATUS_OBJECT), [status]);

	// -------------------------------------------------------- LOGS
	// console.log(
	//   { isSelect, QueryString, DEFAULT_STATUS_OBJECT, SORT_OPTION_OBJECT, dating },
	//   new Date(QueryString.from),
	//   "isSelect"
	// );

	const handleToggleSubTable = ({ options, data, row }) => setToggleSubTable(toggleSubTable?.row == row ? false : { options, data, row });

	return (
		<Row>
			<Col className="col-12 pb-5 pb-md-0">
				<div className="card  p-1">
					{dating ? (
						<div className="d-flex mb-2 flex-wrap">
							{/* navigateUrl */}
							{dating?.component || (
								<>
									<div>
										<FieldDate
											disabled={dating?.isLoading}
											// isClearable
											{...{
												label: "from",
												onChange: (e) => {
													console.log({ e });
													try {
														if (!e) return navigateUrl({ from: undefined, to: undefined, page: 1 });
														let date = moment(e);
														if (dating?.from?.changeTime) date = dating.from.changeTime(new Date(date));
														//  date.setHours(03)
														navigateUrl({ from: new Date(date).toISOString(), page: 1 });
													} catch (error) {
														console.log({ error });
														navigateUrl({ from: undefined, to: undefined, page: 1 });
													}
												},
												selected: (QueryString.from && new Date(QueryString.from)) || dating.defaultValue,
												placeholderText: t("selectDate"),
												...(dating?.minDate && { minDate: dating?.minDate }),
												maxDate: dating?.maxDate || new Date(),
											}}
											{...dating?.props}
										/>
									</div>

									<div className="mb-2">
										<FieldDate
											disabled={dating?.isLoading}
											// isClearable
											{...{
												label: "to",
												onChange: (e) => {
													try {
														if (!e) return navigateUrl({ to: undefined, page: 1 });

														let date = moment(e);
														if (dating?.to?.changeTime) date = dating.to.changeTime(new Date(date));
														//  date.setHours(03)
														navigateUrl({ to: new Date(date).toISOString(), page: 1 });
													} catch (error) {
														console.log({ error });
														navigateUrl({ to: undefined, page: 1 });
													}
												},
												selected: QueryString.to && new Date(QueryString.to),
												startDate: QueryString.from && QueryString.from,
												placeholderText: t("selectDate"),
												minDate: QueryString.from && new Date(QueryString.from),
												maxDate: dating?.maxDate || new Date(),

												// dayClassName: date => date.getTime() < (((new Date(QueryString.from)).getTime())) ? 'disabled-date' : undefined
											}}
											{...dating?.props}
										/>
									</div>
								</>
							)}

							<div className="d-flex align-items-center mb-2">
								{dating?.buttons ? (
									<Dropdown>
										<Dropdown.Toggle className="d-flex align-items-center gap-2" variant="success" id="dropdown-basic" disabled={dating?.isLoading}>
											{dating?.isLoading ? <Loading size={"sm"} /> : ""}
											{t("exportExcel")}
										</Dropdown.Toggle>

										<Dropdown.Menu>
											{dating?.buttons?.map((x) => {
												return (
													<Dropdown.Item onClick={x.onClick} className="py-2">
														<i className="mdi mdi-download mr-2" />
														{/* {t("static")} */}
														{x?.title}
													</Dropdown.Item>
												);
											})}

											{/* <Dropdown.Item className="py-2"><i className="mdi mdi-download" />   {t("table")}</Dropdown.Item> */}
											<Dropdown.Divider />
											<Dropdown.Item className="py-2" eventKey="4">
												{" "}
												{t("exportExcel")}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								) : dating?.button ? (
									<Button disabled={!QueryString.from || !QueryString.to} onClick={dating?.button?.onClick}>
										<i className="mdi mdi-download" />
										Excel Olarak İndir
									</Button>
								) : (
									""
								)}
								{dating?.side || ""}
							</div>
						</div>
					) : (
						""
					)}

					{excelButton?.show ? (
						<div className="d-flex my-2">
							{" "}
							<Button className="d-flex align-items-center gap-2" disabled={excelButton?.disabled || excelButton?.isLoading} onClick={excelButton?.onClick}>
								{excelButton?.isLoading ? <Loading size={"sm"} /> : <i className="mdi mdi-download" />}

								{excelButton.title || "Excel Olarak İndir"}
							</Button>{" "}
						</div>
					) : (
						""
					)}

					<div className="justify-content-between d-flex flex-column align-items-center">
						<div className={"d-flex w-100  justify-content-between flex-wrap"}>
							<div className="">
								{!disables.all ? (
									<div className="filter-sort-wrapper d-flex mb-0 flex-wrap">
										{!disables?.action && actions?.onClick && isSelect && isSelect > 0 ? (
											<Dropdown variant="primary" className="mt-3">
												<Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3">
													<span>{t(isSelect)}</span> {t("action")}
												</Dropdown.Toggle>

												<Dropdown.Menu className="dropDown-menu-custom">
													<Dropdown.Header className="text-capitalize" header>
														{t("anActionForAllTheSelect")}
													</Dropdown.Header>
													{Children.toArray(
														DEFAULT_STATUS?.slice(1, DEFAULT_STATUS?.length)?.map((action) => (
															<Dropdown.Item
																key={action.label + "_ACTION"}
																onClick={() => actions?.onClick(action)}
																className="dropDown-item-custom text-capitalize">
																{t(action?.label)}
															</Dropdown.Item>
														))
													)}
												</Dropdown.Menu>
											</Dropdown>
										) : (
											""
										)}

										{count >= 10 ? (
											<Dropdown variant="primary" className="mt-3">
												<Dropdown.Toggle id="dropdown-basic" className="btn-sm filter-sort mr-3">
													{t(`limitAuthor`, { author: limit })}
												</Dropdown.Toggle>

												<Dropdown.Menu className="dropDown-menu-custom">
													<Dropdown.Header className="text-capitalize" header>
														{"Select table limit"}
													</Dropdown.Header>
													{Children.toArray(
														tableDisplayed.map((x) => (
															<Dropdown.Item
																key={x.label}
																onClick={() => navigateUrl({ limit: x.value })}
																className="dropDown-item-custom text-capitalize"
																active={x.value === limit}>
																{t(x?.value)}
															</Dropdown.Item>
														))
													)}
												</Dropdown.Menu>
											</Dropdown>
										) : (
											""
										)}

										{/* -------------------------STATUS------------------------------------------------ */}
										{status ? (
											<Dropdown variant="primary" className="mt-3">
												<Dropdown.Toggle id="dropdown-basic" className="btn-sm text-capitalize mr-3">
													{t(DEFAULT_STATUS_OBJECT[QueryString.isActive]?.label || "filterByStatus")}
												</Dropdown.Toggle>

												<Dropdown.Menu className="dropDown-menu-custom">
													<Dropdown.Header className="text-capitalize" header>
														{t("filterByStatus")}
													</Dropdown.Header>

													{/* <PerfectScrollbar className=""> */}

													{Children.toArray(
														DEFAULT_STATUS.map((x) => (
															<Dropdown.Item
																onClick={() => navigateUrl({ isActive: x.value != undefined && String(x.value) })}
																className="dropDown-item-custom text-capitalize"
																active={x.value === DEFAULT_STATUS_OBJECT[QueryString.isActive]?.value}>
																{t(x?.label)}
															</Dropdown.Item>
														))
													)}
													{/* </PerfectScrollbar> */}
												</Dropdown.Menu>
											</Dropdown>
										) : (
											""
										)}
										{/*  ---------------------- filtering ------------------------------------------ */}
										<Dropdown variant="primary" className="mt-3">
											<Dropdown.Toggle id="dropdown-basic" className="btn-sm ">
												{t(SORT_OPTION_OBJECT[QueryString.sort]?.label || "sortBy")}
											</Dropdown.Toggle>

											<Dropdown.Menu className="dropDown-menu-custom">
												<Dropdown.Header className="text-capitalize" header>
													{t("sortBy")}
												</Dropdown.Header>

												{/* <PerfectScrollbar className=""> */}
												{Children.toArray(
													SORT_OPTION.map((sort) => (
														<Dropdown.Item
															onClick={() => navigateUrl({ sort: sort.value })}
															className="dropDown-item-custom text-capitalize"
															active={sort.value?.toLowerCase() === QueryString.sort?.toLowerCase()}>
															{t(sort?.label)}
														</Dropdown.Item>
													))
												)}
												{/* </PerfectScrollbar> */}
											</Dropdown.Menu>
										</Dropdown>
									</div>
								) : (
									""
								)}
							</div>

							{!disables.all && search ? (
								<div className="mt-3 ">
									<form className="input-group search-area" onSubmit={handleSearch}>
										<input
											type="text"
											className="form-control custom-search-input"
											placeholder={search.placeholder || "search ..."}
											defaultValue={search.defaultValue}
											ref={inputRef}
										/>
										<div className="input-group-append">
											<span className="btn btn-primary px-3 " onClick={handleSearch}>
												<i className="mdi mdi-magnify"></i>
											</span>
										</div>
									</form>
									{QueryString.search && (
										<div className="cancel-btn-wrapper" onClick={cancelSearchHandler}>
											<i className="flaticon-034-cancel"></i>
										</div>
									)}
								</div>
							) : (
								""
							)}
						</div>
					</div>
					<div className="">
						{!data?.length ? (
							loading ? (
								<TableSkeleton />
							) : (
								<div className="text-center h2 text-capitalize text-muted font-weight-normal mt-5">There is no data to show !</div>
							)
						) : (
							<>
								<div className=" stretch-card mt-3">
									<div id="table_wrapper" className="table-responsive pb-5">
										<table id="example" className="table pb-5">
											<thead>
												<tr role="row">
													{actions?.onClick ? (
														<th className="width50 ">
															<div className="custom-control custom-checkbox checkbox-success check-md  bs_exam_topper_all">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id="checkAll"
																	required=""
																	onChange={(e) => {
																		toggleCheckboxFunAll(e, data);
																	}}
																	checked={selectedAllCheckbox}
																/>
																<label className="custom-control-label" htmlFor="checkAll"></label>
															</div>
														</th>
													) : (
														""
													)}

													{tableOptions?.map(({ header, className, style }, i) => (
														<th key={i} className={className} style={style}>
															{t(header)}
														</th>
													))}
												</tr>
											</thead>
											<tbody>
												{data?.map((d, i) => {
													return (
														<Fragment key={`${d.id}`}>
															<tr
																style={{
																	...(d.orderColor && !isHotel && { backgroundColor: d.orderColor }),
																}}>
																{actions?.onClick ? (
																	<td>
																		<div className="custom-control custom-checkbox checkbox-success check-md bs_exam_topper">
																			<input
																				type="checkbox"
																				className="custom-control-input"
																				id={"customCheckBox" + i}
																				required=""
																				checked={stateData[d.id]}
																				onClick={() => {
																					toggleCheckboxFun(d);
																				}}
																			/>
																			<label className="custom-control-label" htmlFor={"customCheckBox" + i}></label>
																		</div>
																	</td>
																) : (
																	""
																)}
																{tableOptions?.map(({ Component, className, style, subTable }, j) => {
																	// let inFunc = typeof Da === "function";
																	// let inObject = typeof Da === "object";
																	// let subTableData = null
																	// if (subTable) subTableData = subTable?.getData(d)
																	// console.log({ subTable });
																	if (Component)
																		return (
																			<Fragment key={`child-` + (i + j)}>
																				<td
																					className={className}
																					style={style}
																					onClick={() =>
																						subTable &&
																						handleToggleSubTable({
																							options: subTable?.options,
																							data: subTable?.getData?.(d),
																							row: i,
																						})
																					}>
																					<Component param={d} t={t} />
																				</td>
																			</Fragment>
																		);
																})}
															</tr>
															{toggleSubTable?.row == i ? (
																<tr className="bg-aliceblue">
																	<td colSpan={tableOptions?.length + (actions?.onClick ? 1 : 0)}>
																		<table id="example" className="table pb-5">
																			<thead>
																				<tr role="row">
																					{toggleSubTable?.options?.map(({ header, className, style }, i) => (
																						<th key={i} className={className} style={style}>
																							{t(header)}
																						</th>
																					))}
																				</tr>
																			</thead>

																			<tbody>
																				{toggleSubTable?.data?.map((c, i) => {
																					return (
																						<Fragment key={`${d.id}+${i}`}>
																							<tr
																								style={{
																									...(c.orderColor && !isHotel && { backgroundColor: d.orderColor }),
																								}}>
																								{toggleSubTable?.options?.map(({ Component, className, style }, j) => {
																									// let inFunc = typeof Da === "function";
																									// let inObject = typeof Da === "object";
																									// let subTableData = null
																									// if (subTable) subTableData = subTable?.getData(d)
																									// console.log({ subTable });
																									if (Component)
																										return (
																											<Fragment key={`child-` + (i + j)}>
																												<td className={className} style={style}>
																													<Component param={c} parent={d} row={i} t={t} />
																												</td>
																											</Fragment>
																										);
																								})}
																							</tr>
																						</Fragment>
																					);
																				})}
																			</tbody>
																		</table>
																	</td>
																</tr>
															) : (
																""
															)}
														</Fragment>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>

								<Row>
									{!disables.all ? (
										<Col sm={6} md={6}>
											{`Showing ${showing} out of ${count} entries`}
										</Col>
									) : (
										""
									)}

									<Col sm={6} md={6}>
										<div className="pagination-container">
											<PaginationM {...pagination} onClick={(page) => navigateUrl({ page })} />
										</div>
									</Col>
								</Row>
							</>
						)}
						{/* {!data?.length ? loading ? <TableSkeleton /> : <div>There is no data to show!</div> : ""} */}
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default TableComponent;

{
	/* <Link to={routes.network.add}>
                            <Button className="mr-2" variant="info btn-rounded">
                                <span className="btn-icon-left text-info">
                                    <i className="fa fa-plus color-info" />
                                </span>
                                Add
                            </Button>
                        </Link> */
}
