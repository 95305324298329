import { ERRORS } from "enumerations";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	shortDesc: "shortDesc",
	desc: "desc",
	stock: "stock",
	priceList: "priceList",
	price: "price",
	buyPrice: "buyPrice",
	images: "images",
	translations: "translations",

	translate_name: "translate_name",
	translate_shortDesc: "translate_shortDesc",
	translate_desc: "translate_desc",
	translate_language: "translate_language",
	language: "language",

	integrationCode: "integrationCode",
	listOrderNumber: "listOrderNumber",
	isRecommended: "isRecommended",
	hideInAll: "hideInAll",
	isNewProduct: "isNewProduct",
	isNewProduct: "isNewProduct",
	isFastDelivery: "isFastDelivery",
	delivery: "delivery",
	isActive: "isActive",
	category: "category",
	kdv: "kdv",
	branch: "branch",
	isCustomBranch: "isCustomBranch",
	branchProductName: "branchProductName",
	promotionCodes: "promotionCodes",
	code: "code",
	isUsed: "isUsed",
	isMeloProduct: "isMeloProduct",
	meloServiceTimeMinutes: "meloServiceTimeMinutes",
	meloTelegramChatId: "meloTelegramChatId",
	meloMaxHourMinutes: "meloMaxHourMinutes",
	meloMaxDays: "meloMaxDays",
	meloStartTime: "meloStartTime",
	meloEndTime: "meloEndTime",
	isVarsapp: "isVarsapp",
	varsappTelegramChatId: "varsappTelegramChatId",
	isNetworkDry: "isNetworkDry",
	isCampaign: "isCampaign",
	isCourierDisabled: "isCourierDisabled",
	isDiscountView: "isDiscountView",
};

const translations = yup
	.object({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour("name")).label("name"),
		[fieldNames.shortDesc]: yup.string().required(ERRORS.pleaseEnterYour("shortDesc")).label("shortDesc"),
		[fieldNames.desc]: yup.string(),
		[fieldNames.language]: yup.object().required(ERRORS.pleaseEnterYour("language")).label("language"),
	})
	.defined();

const promotionCodes = yup
	.object({
		[fieldNames.code]: yup.string().label("code"),
		// [fieldNames.code]: yup.string().required(ERRORS.pleaseEnterYour("code")).label("code"),
		[fieldNames.isUsed]: yup.bool().default(false).label("code"),
	})
	.defined();

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.name]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.name)).label(fieldNames.name),
		[fieldNames.images]: yup.lazy((value) => {
			switch (typeof value) {
				case "object":
					return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.branch)).label(fieldNames.branch); // schema for object
				case "string":
					return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.branch)).label(fieldNames.branch); // schema for string
				default:
					return yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.branch)).label(fieldNames.branch); // here you can decide what is the default
			}
		}),
		// [fieldNames.branch]: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.branch)).label(fieldNames.branch),
		[fieldNames.shortDesc]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.shortDesc)).label(fieldNames.shortDesc),
		[fieldNames.desc]: yup.string().label(fieldNames.desc),
		[fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.stock),
		[fieldNames.price]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.price)).label(fieldNames.price),
		// [fieldNames.integrationCode]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.integrationCode)).label(fieldNames.integrationCode),

		[fieldNames.isActive]: yup.object().required(ERRORS.pleaseEnterYour("status")).label("status"),
		[fieldNames.images]: yup.lazy((value) => {
			switch (typeof value) {
				case "object":
					return yup.object().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // schema for object
				case "string":
					return yup.string().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // schema for string
				default:
					return yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.images)).label(fieldNames.images); // here you can decide what is the default
			}
		}),
		// [fieldNames.stock]: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.stock)).label(fieldNames.name),
		[fieldNames.translations]: yup.array().of(translations),
		// [fieldNames.promotionCodes]: yup.array().of(promotionCodes),
		[fieldNames.isNewProduct]: yup.bool().default(false).label(fieldNames.isNewProduct),
		[fieldNames.isFastDelivery]: yup.bool().default(false).label(fieldNames.isFastDelivery),

		[fieldNames.integrationCode]: yup.mixed().required(ERRORS.pleaseEnterYour(fieldNames.integrationCode)).label(fieldNames.integrationCode),
		[fieldNames.isRecommended]: yup.bool().default(false).label(fieldNames.isRecommended),
		[fieldNames.hideInAll]: yup.bool().default(false).label(fieldNames.hideInAll),

		// [fieldNames.kdv]: yup
		//   .string()
		//   .when(fieldNames.isRecommended, {
		//     is: true,
		//     then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.kdv)),
		//   })
		//   .label(fieldNames.kdv),
		// [fieldNames.delivery]: yup
		//   .object()
		//   .when(fieldNames.isRecommended, {
		//     is: true,
		//     then: yup.object().required(ERRORS.pleaseEnterYour(fieldNames.delivery)),
		//   })
		//   .label(fieldNames.delivery),
		// [fieldNames.email]: yup.string().required(ERRORS.pleaseEnterYour("Email")).label("email"),
		// [fieldNames.password]: yup
		//   .string()
		//   .matches(regex.passwordRegExp, "Password must at least 8 characters, including uppercase,lowercase,number and symbol")
		//   .label("Password"),
		// [fieldNames.confirm_password]: yup.string().oneOf([yup.ref("password"), null], "Passwords must match"),
		// [fieldNames.role]: yup.object().label("role"),
		// [fieldNames.phoneNumber]: yup.string().label("mobile-number"),
		// [fieldNames.isActive]: yup.object().label("status"),
		// [fieldNames.type]: yup.object().required(ERRORS.pleaseSelectYour("Type")).label("Type"),
		// [fieldNames.image]: yup.object().nullable().required(ERRORS.pleaseSelectYour("image")).label("image"),

		[fieldNames.isMeloProduct]: yup.boolean(),
		meloServiceTimeMinutes: yup.string().when("isMeloProduct", {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloServiceTimeMinutes)),
			otherwise: yup.string(),
		}),
		[fieldNames.meloTelegramChatId]: yup.string().when("isMeloProduct", {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloTelegramChatId)),
			otherwise: yup.string(),
		}),
		// [fieldNames.meloMaxHourMinutes]: yup.string().when('isMeloProduct', {
		//   is: true,
		//   then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloMaxHourMinutes)),
		//   otherwise: yup.string()
		// }),
		[fieldNames.meloMaxDays]: yup.string().when("isMeloProduct", {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloMaxDays)),
			otherwise: yup.string(),
		}),
		[fieldNames.meloStartTime]: yup.string().when("isMeloProduct", {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloStartTime)),
			otherwise: yup.string(),
		}),
		[fieldNames.meloEndTime]: yup.string().when("isMeloProduct", {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.meloEndTime)),
			otherwise: yup.string(),
		}),

		[fieldNames.isVarsapp]: yup.boolean(),
		[fieldNames.varsappTelegramChatId]: yup.string().when(fieldNames.isVarsapp, {
			is: true,
			then: yup.string().required(ERRORS.pleaseEnterYour(fieldNames.varsappTelegramChatId)),
			otherwise: yup.string(),
		}),
	});
